import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, { Optional } from "@/__main__/data-model.mjs";

const BaseItem = {
  title: String,
  description: Optional(String),
  assetType: String,
  urlName: String,
  media: [
    {
      title: String,
      image: String,
      video: Optional(String),
    },
  ],
} satisfies ModelDefinition;

const OfferItem = {
  offerId: String,
  title: String,
  assetType: String,
  callout: Optional({
    text: String,
    intensity: String,
  }),
  image: String,
  color1: Optional(String),
  color2: Optional(String),
  color3: Optional(String),
  textBackgroundColor: Optional(String),
  pricing: {
    basePrice: Number,
    finalPrice: Number,
    amountOff: Number,
  },
  inDate: Date,
  outDate: Date,
  items: [BaseItem],
} satisfies ModelDefinition;

const model = {
  id: String,
  sections: [
    {
      displayName: String,
      sectionID: String,
      offers: [OfferItem],
    },
  ],
} satisfies ModelDefinition;

const validateFn = createModel(model);
export type ItemShop = FromModel<typeof validateFn>;
export type Offer = ItemShop["sections"][number]["offers"][number];
export type Item = Offer["items"][number];

export default validateFn;
