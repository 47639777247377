import getData from "@/__main__/get-data.mjs";
import fetchReactions from "@/game-fortnite/fetches/reactions.mjs";
import ItemShop from "@/game-fortnite/models/item-shop.mjs";
import * as API from "@/game-fortnite/utils/api.mjs";

export function fetchItemShop() {
  return getData(API.getItemShop(), ItemShop, ["fortnite", "itemShopV2"]);
}
export async function fetchData() {
  const itemShop = await fetchItemShop();
  const ids = itemShop?.sections
    ?.map((section) => {
      return section.offers.map((offer) => [
        offer.offerId,
        offer.items.map((item) => item.urlName),
      ]);
    })
    ?.filter((d) => Boolean(d))
    ?.flat(3);

  if (ids?.length === 0) return;

  return fetchReactions(ids);
}

export default fetchData;
